<template>
    <div class="local-menu">
        <v-btn class="main" @click.stop="placeMain" >メイン</v-btn>
        <v-btn class="forecast" @click.stop="forecastGraph" >水位予測</v-btn>
        <v-btn class="predischarge" @click.stop="preDischarge" >事前放流可能水位</v-btn>
        <v-btn class="lowlevel" @click.stop="lowLevel">低水位管理の支援</v-btn>
		<v-btn class="flood" @click.stop="floodControl">洪水調節効果評価</v-btn>
    </div>
</template>
<script>

export default {
    props: {
        item: {
            type: Object,
            default: () => []
        }
    }
    ,
    methods: {
		placeMain: function() {
			let index = this.$store.getters.getPlaceIndexById(this.item.id);
			this.$router
				.push({
					name: "Place",
					params: { id: index, item: this.item },
				})
				.catch(() => {});
		},
		forecastGraph: function() {
			let index = this.$store.getters.getPlaceIndexById(this.item.id);
			if (this.$store.state.places.summaryData[index].initialized) {
				alert("まず、管理画面にて溜池諸元の各種パラメータを設定してください");
				return;
			}
			this.$router
				.push({
					name: "ForecastGraph",
					params: { id: index, item: this.item },
				})
				.catch(() => {});
		},
		preDischarge: function() {
			let index = this.$store.getters.getPlaceIndexById(this.item.id);
			if (this.$store.state.places.summaryData[index].initialized) {
				alert("まず、管理画面にて溜池諸元の各種パラメータを設定してください");
				return;
			}
			this.$router
				.push({
					name: "PreDischarge",
					params: { id: index, item: this.item },
				})
				.catch(() => {});
		},
		lowLevel: function(){
			let index = this.$store.getters.getPlaceIndexById(this.item.id);
			if (this.$store.state.places.summaryData[index].initialized) {
				alert("まず、管理画面にて溜池諸元の各種パラメータを設定してください");
				return;
			}
			this.$router
				.push({
					name: "LowLevel",
					params: { id: index, item: this.item },
				})
				.catch(() => {});
		}
		,
		floodControl: function() {
			let index = this.$store.getters.getPlaceIndexById(this.item.id);
			if (this.$store.state.places.summaryData[index].initialized) {
				alert("まず、管理画面にて溜池諸元の各種パラメータを設定してください");
				return;
			}
			this.$router
				.push({ name: "FloodControl", params: { id: index, item: this.item } })
				.catch(() => {});
		},
	},
}
</script>
<style lang="scss">
    .local-menu { 
		background-color: #484848; padding: 10px 0 0px 10px; display: flex; flex-wrap: wrap; align-items: center; justify-content: flex-start;
        button { width: calc( 25% - 10px ); margin-right: 10px; margin-bottom: 10px; height: 60px !important; background-color: #F4F4F4; padding:0 10px !important; 
			span { font-size: 18px; justify-content: center; font-weight: bold; color: #707070; }
			&:before {
				background-position: 0% 50%; background-repeat:no-repeat; background-size: contain; content:''; display: inline-block; width: 20%; height: 70%; position: static;
				opacity:0.5; background-color: transparent; 
			}
			&.main:before { background-image:url('~@/assets/icon_data.svg'); }
			&.forecast:before { background-image:url('~@/assets/icon_forecast.svg'); }
			&.predischarge:before { background-image:url('~@/assets/icon_predischarge.svg'); }
			&.lowlevel:before { background-image:url('~@/assets/icon_lowlevel.svg'); }
			&.flood:before { background-image:url('~@/assets/icon_evaluate.svg'); }
			@media screen and (min-width:1500px){
				width: calc( 20% - 10px );

			}
			@media screen and (max-width:930px){
				width: calc( 33% - 10px );

			}
			@media screen and (max-width:720px){
				width: calc( 50% - 10px );

			}
			@media screen and (max-width:480px){
				span { font-size: 12px }
				&:before { width: 10%; }
			}
        }
		@media screen and (max-width:720px){
			background-color: #707070; padding:0;
			button { display:none }
		}
    } 
	.container.place .local-menu .main
	,.container.predischarge .local-menu .predischarge
	,.container.forecast .local-menu .forecast 
	,.container.flood .local-menu .flood 
	,.container.lowlevel .local-menu .lowlevel {
		border: 4px solid #41D526; background-color:white; 
		&:before { opacity:1 !important; }
		span { color: black; }
	}

</style>