<template>
	<div class="main-header"
		:style="[
			{
				'background-color': current_prop.color
				,'color': current_prop.text_color
			},
		]"
	>
		<div>
			<span>{{ item.id }}</span>
			<span>{{ item.district }}</span>
			<span>{{ item.name }}</span>
		</div>
		<div>
			<span>{{ waterlevel }}</span>
			<span class="name">{{ current_prop.name }}</span>
		</div>
		
	</div>
</template>
<script>
import Vue from "vue";
import utility from "../mixins/utility";
Vue.mixin(utility);

export default {
    props: {
        item: {
            type: Object,
            default: () => []
        }
		
    }
    ,
	computed: {
		waterlevel() {
			return  this.waterLevelFormat(this.item.waterlevel.level[this.item.waterlevel.level.length-1])
		},
		current_prop: function(){
			return this.$store.getters.getAlertColor(this.item)
		}
		,fontColor() {
			return  this.current_prop.text_color
		},
	}
	,
    methods: {
		
	}
	,
	
}
</script>
<style lang="scss" scoped>
    span.name { border:1px solid v-bind(fontColor); display: inline-block; padding: 0 .5em; margin-right:0 !important;  }

</style>