<template>
  <div>
    <div class="block">
      <div class="header" @click="displayChart = !displayChart" style="cursor:pointer;">低水位管理の支援</div>
      <div v-if="displayChart" class="body" > 
      <LineChartGenerator
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
      ref="linechart"
      />
      </div>
      <div v-if="displayChart" class="footer">
        <div></div>
        <div>
          <button @click="onClickZoomOut($refs.linechart)">-</button>
          <button @click="onClickZoomIn($refs.linechart)">+</button>
          <button @click="onClickResetZoom($refs.linechart)">リセット</button>
        </div>
      </div>
      <div v-if="!displayChart" class="footer"></div>
    </div>
  </div>
</template>
  
<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import annotationPlugin from 'chartjs-plugin-annotation'; // アノテーション描画で必要
import zoomPlugin from 'chartjs-plugin-zoom';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  TimeScale,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  PointElement,
  Filler
  ,annotationPlugin  // アノテーション描画で必要
  , zoomPlugin
)

// Bootstrap-Vue references : 
// https://bootstrap-vue.org/docs/components/form-input
// https://bootstrap-vue.org/docs/components/form-datepicker

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator
  }
  ,
  async created() {
    this.$parent.loading = true;
    let start = new Date(2022, 0, 1);
    let end = new Date();
    let index = this.$store.getters.getPlaceIndexById(this.item.id);
    let data = await this.$store.dispatch("getWaterlevelYearlyData", { index: index, start: start, end: end });
    this.setChartData(data);
    this.$parent.loading = false;
  }
  ,
  mounted() {
	}
  ,
  props: {
    chartId: {
      type: String,
      default: 'block-water-history'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    // plugins: {
    //   type: Array,
    //   default: () => []
    // }
    // ,
    item: {
      type: Object,
      default: () => []
    }
  },
  computed: {
  },
  data() { 
    return {
      chart:null,
      chartData: {},
      chartOptions: {},
      legend_width: 150,
      displayChart: true,
      waterlevels: null,
      plugins: [
          {
            // 特殊なレジェンドの作成
            id: 'custom_legends',
            beforeDraw: (function (chart) {
              // console.log(chart)
              try{
                const w = this.legend_width
                let h = 50
                const scales = chart.scales
                const chartArea = chart.chartArea
                // const thresholds = this.item.alertThresholds
                // const c = this.$store.getters.getColors
                let y = 0
                let ctx = chart.ctx;

                const xpos_0 = chartArea.left + chartArea.width 
                const xpos_1 = chart.width - w

                // 単位
                ctx.textAlign = 'left';
                ctx.fillStyle = "#555";
                ctx.font = "normal 14px sans-serif";
                ctx.fillText('水位(m)', 0, 14 );
                ctx.restore();

              } catch( e){
                  console.log(e)
              }
            }).bind(this)
          }
        ]
    }
  }
  ,methods : {
    onClickRange(e,r){
      console.log(e,r)
    },
    onClickZoomIn(e){
      let chart = e.getCurrentChart();
      if(chart.getZoomLevel() < 10){
        chart.zoom( 1.2 , 'x')
      }
    }
    ,
    onClickZoomOut(e){
      let chart = e.getCurrentChart()
      chart.zoom( 0.8 , 'x')
    }
    ,
    onClickResetZoom(e){
      let chart = e.getCurrentChart()
      chart.resetZoom()
    }
    ,
    onMouseMove(e, c){
      // console.log(e,c)
      let chart = c.getCurrentChart()
      let area = chart.chartArea
      if(area.left -10 < e.offsetX && e.offsetX < area.left + area.width +10 && area.top - 10  < e.offsetY && e.offsetY < area.top+area.height + 10){
        document.body.classList.add('can-zoom')
      } else {
        document.body.classList.remove('can-zoom')
      }
    }
    ,
    scrollTop: function() {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		reloadGraph: function() {
			this.log = "";
			this.setYearlyData();
		},
    setChartData: function(data) {
        this.chartData = {
          labels: data.labels,
          datasets: [
            {
              label: '水位 (2022年)'
              ,fill : "false"
              ,backgroundColor: 'rgba(113,192,255,0.8)'
              ,borderColor: "#3e95cd"
              ,borderWidth: 1
              ,pointRadius: 1
              ,data: data.waterlevels[0]
              ,yAxisID: 'water_level'
            },
            {
              label: '水位 (2023年)'
              ,fill : "falase"
              ,borderColor: 'rgba(107,207,215,1)'
              ,backgroundColor: 'rgba(147,247,255,0.6)'
              ,borderWidth: 1
              ,pointRadius: 1
              ,data: data.waterlevels[1]
              ,yAxisID: 'water_level'
            }
          ]
        };
        this.chartOptions = {
          responsive: true,
          maintainAspectRatio: false
          ,
          layout: {
              padding: {
                top: 40
              }
          }
          ,
          scales: {
            x: {
              ticks: {
                // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                callback: function(val) {
                  // let num_ticks = this.ticks.length
                  let s = ''
                  let ds = new Date( this.getLabelForValue(val) )
                  if(ds.getDate() == 1 || ds.getDate() == 15 ){
                    s = (ds.getMonth() + 1) + '/' + ds.getDate() 
                    return s;
                  }
                },
                color: 'black',
              }
            },
            water_level: {
              type: 'linear',
              display: true,
              position: 'left',
              grid: {
                drawOnChartArea: true, 
              },
            },
          },
          plugins: {
              tooltip: {
                  callbacks: {
                    title: function(context){
                      let title = new Date(context[0].label);
                      return (title.getMonth() + 1) + '/' + title.getDate() + ' ' + ('0' + title.getHours()).slice(-2) + ':' + ('0' + title.getMinutes()).slice(-2)
                    },
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ' : ';
                        }
                        if (context.parsed.y !== null) {
                          let prefix = "";
                          if (context.parsed.y >= 0) prefix = "+";
                          label += prefix + new Intl.NumberFormat('en-US', { style: 'decimal', maxDigits: 2 }).format(context.parsed.y) + ' m';
                        }
                        return label;
                    }
                  }
              },
              legend: {
                display: true
                ,position: 'bottom'
                ,labels: {
                  font: {
                    size: 20
                    ,weight : 'bold'
                  }
                }
              },
              zoom:{
                limits:{
                  // x : { min: 20, max: 100 }
                },
                zoom : {
                  wheel : {
                    enabled: true,
                    speed : 0.01
                  }
                  ,mode : 'x'
                }
                ,pan : {
                    enabled: true
                    ,mode : 'x'
                  }
              }
              , autocolors: false
              , annotation: { // チャート内に線やボックスを描ける
                  animations: {
                    numbers: {
                      properties: [], // keep empty to avoid a bug // 'x', 'y', 'x2', 'y2', 'width', 'height', 'radius'
                      type: 'number'
                    },
                  },
                  annotations: {
                  }
              }
            
          }
      }
    }
  }
}
</script>

<style>

</style>